<template>
  <div>
    <button
      ref="trigger"
      class="w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ml-3"
      :class="{ 'bg-gray-200': searchOpen }"
      @click.prevent="searchOpen = !searchOpen; handleSearchFocus()"
      aria-controls="search-modal"
    >
      <span class="sr-only">Search</span>
      <svg class="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path class="fill-current text-gray-500" d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
        <path class="fill-current text-gray-400" d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
      </svg>
    </button>
    <!-- Modal backdrop -->
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-out duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="searchOpen" class="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity" aria-hidden="true"></div>
    </transition>
    <!-- Modal dialog -->
    <transition
      enter-active-class="transition ease-in-out duration-200"
      enter-from-class="opacity-0 translate-y-4"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in-out duration-200"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-4"
    >
      <div v-show="searchOpen" id="search-modal" class="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6" role="dialog" aria-modal="true">
        <div ref="searchContent" class="bg-white overflow-auto max-w-2xl w-full max-h-full rounded shadow-lg">
          <!-- Search form -->
          <form class="border-b border-gray-200">
            <div class="relative">
              <label for="modal-search" class="sr-only">Search</label>
              <input id="modal-search" class="w-full border-0 focus:ring-transparent placeholder-gray-400 appearance-none py-3 pl-10 pr-4" type="search" placeholder="Search Anything…" ref="searchInput" />
              <button class="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                <svg class="w-4 h-4 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-4 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                  <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                </svg>
              </button>
            </div>
          </form>
          <div class="py-4 px-2">
            <!-- Recent searches -->
            <div class="mb-3 last:mb-0">
              <div class="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">Recent searches</div>
              <ul class="text-sm">
                <SearchItem
                    v-for="result in searchResults"
                    :key="result.id"
                    :result="result"
                />
<!--                <li>
                  <a class="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group" href="#0" @click="searchOpen = false" @focusin="searchOpen = true" @focusout="searchOpen = false">
                    <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                      <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                    </svg>
                    <span>Form Builder - 23 hours on-demand video</span>
                  </a>
                </li>
                <li>
                  <a class="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group" href="#0" @click="searchOpen = false" @focusin="searchOpen = true" @focusout="searchOpen = false">
                    <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                      <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                    </svg>
                    <span>Access Mosaic on mobile and TV</span>
                  </a>
                </li>
                <li>
                  <a class="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group" href="#0" @click="searchOpen = false" @focusin="searchOpen = true" @focusout="searchOpen = false">
                    <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                      <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                    </svg>
                    <span>Product Update - Q4 2021</span>
                  </a>
                </li>
                <li>
                  <a class="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group" href="#0" @click="searchOpen = false" @focusin="searchOpen = true" @focusout="searchOpen = false">
                    <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                      <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                    </svg>
                    <span>Master Digital Marketing Strategy course</span>
                  </a>
                </li>
                <li>
                  <a class="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group" href="#0" @click="searchOpen = false" @focusin="searchOpen = true" @focusout="searchOpen = false">
                    <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                      <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                    </svg>
                    <span>Dedicated forms for products</span>
                  </a>
                </li>
                <li>
                  <a class="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group" href="#0" @click="searchOpen = false" @focusin="searchOpen = true" @focusout="searchOpen = false">
                    <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                      <path d="M15.707 14.293v.001a1 1 0 01-1.414 1.414L11.185 12.6A6.935 6.935 0 017 14a7.016 7.016 0 01-5.173-2.308l-1.537 1.3L0 8l4.873 1.12-1.521 1.285a4.971 4.971 0 008.59-2.835l1.979.454a6.971 6.971 0 01-1.321 3.157l3.107 3.112zM14 6L9.127 4.88l1.521-1.28a4.971 4.971 0 00-8.59 2.83L.084 5.976a6.977 6.977 0 0112.089-3.668l1.537-1.3L14 6z" />
                    </svg>
                    <span>Product Update - Q4 2021</span>
                  </a>
                </li>
              </ul>
            </div>
            &lt;!&ndash; Recent pages &ndash;&gt;
            <div class="mb-3 last:mb-0">
              <div class="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">Recent pages</div>
              <ul class="text-sm">
                <li>
                  <a class="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group" href="#0" @click="searchOpen = false" @focusin="searchOpen = true" @focusout="searchOpen = false">
                    <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                      <path d="M14 0H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h8l5-5V1c0-.6-.4-1-1-1zM3 2h10v8H9v4H3V2z" />
                    </svg>
                    <span><span class="font-medium text-gray-800 group-hover:text-white">Messages</span> - Conversation / … / Mike Mills</span>
                  </a>
                </li>
                <li>
                  <a class="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group" href="#0" @click="searchOpen = false" @focusin="searchOpen = true" @focusout="searchOpen = false">
                    <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-white group-hover:text-opacity-50 flex-shrink-0 mr-3" viewBox="0 0 16 16">
                      <path d="M14 0H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h8l5-5V1c0-.6-.4-1-1-1zM3 2h10v8H9v4H3V2z" />
                    </svg>
                    <span><span class="font-medium text-gray-800 group-hover:text-white">Messages</span> - Conversation / … / Eva Patrick</span>
                  </a>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>    
  </div>
</template>

<script>
import {searchindex} from "../../searchindex";
import { ref, nextTick, onMounted, onUnmounted } from 'vue'

import SearchItem from "./SearchItem.vue";

export default {
  name: 'SearchModal',
  components: {
    SearchItem
  },
  setup() {

    const searchOpen = ref(false)
    const trigger = ref(null)
    const searchContent = ref(null)
    const searchInput = ref(null)
    const searchIndex = ref(searchindex)
    const searchResults = ref(null)

    const downloadSearchIndex = async () => {
      const topstories = await (await fetch("https://hacker-news.firebaseio.com/v0/topstories.json")).json()
      const requests = []
      const jsonAnswers = []
      for (const story of topstories) {
         requests.push((fetch("https://hacker-news.firebaseio.com/v0/item/" + story + ".json")))
      }
      const finishedRequests = await Promise.all(requests)
      for (const request of finishedRequests) {
        jsonAnswers.push(request.json())
      }
      const index = await Promise.all(jsonAnswers)
      console.log(index)
      searchIndex.value = index
    }

    const searchHandler = async () => {
      const results = searchIndex.value.filter(item => item.title.toLowerCase().includes(searchInput.value.value.toLowerCase()))
      searchResults.value = results
      console.log(results)
    }

    // give focus to search input
    const handleSearchFocus = () => {
      searchOpen.value && nextTick(() => searchInput.value.focus())
    }

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!searchOpen.value || searchContent.value.contains(target) || trigger.value.contains(target)) return
      searchOpen.value = false
    }

    // close if the esc key is pressed
    const keyHandler = async ({ keyCode }) => {
      if (!searchOpen.value || keyCode !== 27) return
      searchOpen.value = false
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
      document.addEventListener('keyup', searchHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
      document.removeEventListener('keyup', searchHandler)
    })

    return {
      searchOpen,
      trigger,
      searchContent,
      searchInput,
      searchResults,
      handleSearchFocus,
    }
  }
}
</script>