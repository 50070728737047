import { createApp } from 'vue'
import { createI18n} from 'vue-i18n'
import router from './router'
import App from './App.vue'

import './css/style.scss'
import {languages} from "./data/localization.js";

const i18n = createI18n({
    locale: 'de',
    messages: languages,
})

const app = createApp(App)
app.use(i18n)
app.use(router)
app.mount('#app')
