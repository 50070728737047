import { req } from './request.js'
import {login_url, logout_url, refresh_url} from "../data/urls.js";
import {ref} from "vue";
import jwt_decode from "jwt-decode";
import router from "../router.js";

export const loggedIn = ref(false)
export const jwt = ref(null)
const refreshTimeoutID = ref(null)

export default function useAuth() {
    async function login(username, password) {
        const response = await req(login_url, { identity: username, password: password })
        if(response.status === 'success') {
            jwt.value = response.data
            loggedIn.value = true
            refreshTimeoutID.value = setTimeout(refresh, ((jwt_decode(jwt.value).exp-Math.floor(Date.now() / 1000)) * 1000) - 30000)
            await router.push({path: '/'})
        } else {
            console.log('Request not successful',response)
        }

        // TODO: make login request and store jwt and user data
    }

    function logout() {
        req(logout_url).then(async () => {
            clearTimeout(refreshTimeoutID.value)
            jwt.value = null
            loggedIn.value = false
            await router.push('/signin')
        })
    }

    async function register() {
        // TODO: make request to register endpoint with register data / parse response
    }

    async function refresh() {
        console.log("refresh")
        const response = await req(refresh_url)
        if(response.status === 204) {
            loggedIn.value = false
            jwt.value = null
            clearTimeout(refreshTimeoutID.value)
            return await router.push('/signin')
        }
        jwt.value = (await response.json()).data
        loggedIn.value = true
        clearTimeout(refreshTimeoutID.value)
        refreshTimeoutID.value = setTimeout(refresh, ((jwt_decode(jwt.value).exp-Math.floor(Date.now() / 1000)) * 1000) - 30000)
    }

    return { login, logout, register, refresh }
}