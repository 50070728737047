import {  ref } from "vue";

const currentPage=ref(1)
const pageSize=ref(10)
const maxPages=ref(1)
const totalItems = ref(1)

export default function usePagination(){
    function setTotalItems(val) {
        totalItems.value = val
        maxPages.value = Math.ceil(val/pageSize.value)
        if(maxPages.value < 1) {
            maxPages.value = 1
        }
    }

    function setPageSize(val) {
        if(val > 0) {
            pageSize.value=val
        }
    }

    function setPage(val){
        if(val > 0) {
            currentPage.value=val;
        }
    }

    function nextPage() {
        if(currentPage.value < maxPages.value) {
            ++currentPage.value
        }
    }

    function prevPage() {
        if(currentPage.value > 1) {
            --currentPage.value
        }
    }

    return {currentPage, pageSize, maxPages, totalItems, setPageSize, setPage, nextPage, prevPage, setTotalItems}
}

