import {login_url, logout_url, refresh_url, register_url} from "../data/urls.js";
import {jwt} from "./auth.js";

export async function req(url, body, method) {
    // TODO: error handling, when request fails, return only data
    if(body) {
        if(url === login_url || url === register_url) {
            return (await fetch(url, {
                method: 'post',
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                credentials: 'include',
                body: JSON.stringify(body)
            })).json()
        } else {
            if(!method) {
                method = 'post'
            }
            return (await fetch(url, {
                method: method,
                headers: new Headers({
                    Authorization: 'Bearer ' + jwt.value
                }),
                body: JSON.stringify(body)
            })).json()
        }
    } else {
        if(url === refresh_url || url === logout_url) {
            return fetch(url, {
                method: 'get',
                credentials: 'include',
            })
        } else {
            if(!method) {
                method = 'get'
            }
            return (await fetch(url, {
                method: method,
                headers: new Headers({
                    Authorization: 'Bearer ' + jwt.value
                })
            })).json()
        }
    }
}