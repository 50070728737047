const en = {
    message: {
        hello: '{msg} world',
        welcome: 'Good afternoon, {name}. 👋 '
    },
    menu: {
        dashboard: 'Dashboard',
        customers: 'Customers',
    },
    tables: {
        select: 'Select',
        selectall: 'Select all',
        previous: 'Previous',
        next: 'Next',
    },
    pages: {
        customers: {
            title: 'Customers',
            allcustomers: 'All Customers',
            addcustomer: 'Add Customer',
            favourite: 'Favourite',
            name: 'Name',
            email: 'Email',
            address: 'Address',
            phone: 'Phone',
            lastinvoice: 'Last invoice',
            totalspent: 'Total spent',
            contactpersons: 'Contact persons',
            menu: 'Menu'
        }
    },
    locales: {
        en: 'English',
        de: 'German',
    }
}

const de = {
    message: {
        hello: '{msg} Welt',
        welcome: 'Guten Tag, {name}. 👋 '
    },
    menu: {
        dashboard: 'Übersicht',
        customers: 'Kunden',
    },
    tables: {
        select: 'Auswählen',
        selectall: 'Alle auswählen',
        previous: 'Letzte Seite',
        next: 'Nächste Seite',
    },
    pages: {
        customers: {
            title: 'Kunden',
            allcustomers: 'Alle Kunden',
            addcustomer: 'Kunde hinzufügen',
            favourite: 'Favorit',
            name: 'Name',
            email: 'Email',
            address: 'Adresse',
            phone: 'Telefon',
            lastinvoice: 'Letzte Rechnung',
            totalspent: 'Gesamtumsatz',
            contactpersons: 'Kontaktpersonen',
            menu: 'Menü'
        }
    },
    locales: {
        en: "Englisch",
        de: "Deutsch",
    }
}

export const languages = {
    en,
    de,
}