<template>
  <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
    <nav class="mb-4 sm:order-1" role="navigation" aria-label="Navigation">
      <ul class="flex justify-center">
        <li class="ml-3 first:ml-0">
          <a class="btn bg-white border-gray-200 " :class="[(currentPage === 1) ? 'cursor-not-allowed text-gray-300' : 'hover:border-gray-300 text-indigo-500']" href="#0" @click="prevPage">&lt;- Previous</a>
        </li>
        <li class="ml-3 first:ml-0">
          <a class="btn bg-white border-gray-200" :class="[(currentPage === maxPages) ? 'cursor-not-allowed text-gray-300' : 'hover:border-gray-300 text-indigo-500']" href="#0" @click="nextPage">Next -&gt;</a>
        </li>
      </ul>
    </nav>
    <div class="text-sm text-gray-500 text-center sm:text-left">
      Showing <span class="font-medium text-gray-600">{{ (currentPage*pageSize)-pageSize+1 }}</span> to <span class="font-medium text-gray-600">{{ ((currentPage*pageSize) < totalItems) ? (currentPage*pageSize) : totalItems }}</span> of <span class="font-medium text-gray-600">{{ totalItems }}</span> results
    </div>
  </div>
</template>

<script>
import usePagination from "../functions/pagination.js";
const { currentPage, pageSize, prevPage, nextPage, totalItems, maxPages } = usePagination();

export default {
  name: 'PaginationClassic',
  setup() {
    return {
      prevPage,
      nextPage,
      currentPage,
      pageSize,
      totalItems,
      maxPages
    }
  }
}
</script>